import { useNavigate, useSearchParams } from 'react-router-dom';

import { PostSearchFormUsageContext } from 'modules/postSearch/components/Input/PostSearchFormUsageContext';
import { PostSearchInput } from 'modules/postSearch/components/Input/PostSearchInput';
import { trackEvent } from 'utils/analytics/track/trackEvent';

type Props = {
  homePageUrl: string;
  searchPlaceholderText?: string;
  usageContext: PostSearchFormUsageContext;
};

export function PostSearchInputContainer({
  homePageUrl,
  searchPlaceholderText,
  usageContext,
}: Props) {
  const [searchParams] = useSearchParams();
  const initialQuery = searchParams.get('pq') || '';
  const navigate = useNavigate();

  const search = (query: string) => {
    // Algolia has a max query length of 512 bytes
    navigate(`/${homePageUrl}?pq=${query}`);

    trackEvent('Articles: Changed Search Query', { new_query: query });
  };

  return (
    <PostSearchInput
      id="post-search-input"
      key={initialQuery}
      usageContext={usageContext}
      data-qa-id="post-search-input"
      initialQuery={initialQuery}
      onSearch={search}
      placeholder={searchPlaceholderText || getText('Search articles')}
    />
  );
}
