import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const PostSearchInputFormHolder = styled.div`
  flex: 4;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    flex: 1;
  }
`;

export const PostSearchInputFormWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  gap: 11px;
  width: 100%;
`;
