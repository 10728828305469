import { MouseEvent, useRef } from 'react';

import { Form } from 'components/Form/Form';
import { FormSubmitFunction } from 'components/Form/FormContext';
import { Input } from 'components/Input/Input';
import { InputClearButtonSuffix } from 'components/Input/affixes/InputClearButtonSuffix';

import { PostSearchButton } from './PostSearchButton';
import { PostSearchFormUsageContext } from './PostSearchFormUsageContext';
import {
  PostSearchInputFormHolder,
  PostSearchInputFormWrapper,
} from './PostSearchInput.styled';

type PostSearchInputFormValues = {
  query: string;
};

type Props = {
  id: string;
  initialQuery: string;
  placeholder?: string;
  usageContext: PostSearchFormUsageContext;
  onSearch: (query: string) => void;
  'data-qa-id'?: string;
};

export function PostSearchInput({
  id,
  initialQuery,
  placeholder,
  onSearch,
  usageContext,
  'data-qa-id': qaId,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickClear = (e: MouseEvent) => {
    e.stopPropagation();
    onSearch('');
    inputRef.current?.focus();
  };

  const onSubmit: FormSubmitFunction<PostSearchInputFormValues> = ({
    values,
    updateSubmitting,
  }) => {
    onSearch(values.query);
    updateSubmitting(false);
  };

  return (
    <PostSearchInputFormHolder>
      <Form
        analyticsTitle="Post Search Form"
        usageContext="page"
        onSubmit={onSubmit}
        initialValues={{ query: initialQuery }}
        render={({ updateValue }) => (
          <PostSearchInputFormWrapper>
            <Input
              fullHeight
              fullWidth
              inputProps={{
                id,
                placeholder,
                'aria-label': placeholder,
                ref: inputRef,
                defaultValue: initialQuery,
                qaId,
                onChange: (event) =>
                  updateValue('query', event.currentTarget.value),
              }}
              affixVariant="transparent"
              suffix={
                <InputClearButtonSuffix
                  onClear={onClickClear}
                  qaId="post-search-input-clear"
                />
              }
              hideSuffixWhenEmpty
            />

            <PostSearchButton
              qaId="post-search-button"
              iconOnly={usageContext === 'searchResults'}
            />
          </PostSearchInputFormWrapper>
        )}
      />
    </PostSearchInputFormHolder>
  );
}
